import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "../components/header/Header";

const AboutMe = () => {
  return (
    <div>
      <Header />
      AboutMe
    </div>
  );
};
export default AboutMe;
