import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "../components/header/Header";

const Work = () => {
  return (
    <div>
      <Header />
      Work
    </div>
  );
};
export default Work;
